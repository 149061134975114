import BlogIcon from "../../../assets/icons/front-pages/blog.svg";
import FaqsIcon from "../../../assets/icons/front-pages/faqs.svg";
import StoreLinksIcon from "../../../assets/icons/front-pages/link-yellow.svg";
import OrdersIcon from "../../../assets/icons/front-pages/orders.svg";
import PaymentsIcon from "../../../assets/icons/front-pages/payments.svg";
import AboutIcon from "../../../assets/icons/front-pages/about.svg";
import DeliveriesIcon from "../../../assets/icons/front-pages/deliveries.svg";
import PricingIcon from "../../../assets/icons/front-pages/pricing.svg";
import WhyCatlogIcon from "../../../assets/icons/front-pages/board-tick.svg";
import InvoiceLinksIcon from "../../../assets/icons/front-pages/receipt.svg";
import SearchIcon from "../../../assets/icons/front-pages/search-status.svg";
import { NavItemType } from "./nav-dropdown";

const navItems: NavItemType[] = [
  {
    title: "Why Catlog",
    icon: WhyCatlogIcon,
    path: "/why-catlog",
    color: "bg-accent-green-500",
  },
  {
    title: "Products",
    icon: "",
    path: "",
    sub: [
      {
        title: "Store Links",
        description: "Showcase your products and take orders with one link",
        icon: StoreLinksIcon,
        path: "/store-links",
        color: "bg-accent-yellow-500",
      },
      {
        title: "Orders & Customers",
        description: "Easily keep track of all your orders & customers",
        icon: OrdersIcon,
        path: "/orders-and-customers",
        color: "bg-accent-red-500",
      },
      {
        title: "Payments & Invoices",
        description: "Seamlessly collect payments & issue invoices",
        icon: PaymentsIcon,
        path: "/payments",
        color: "bg-accent-green-500",
      },
      {
        title: "Invoice Links",
        description: "Create links you can share with customers to pay you",
        icon: InvoiceLinksIcon,
        path: "/invoice-links",
        color: "bg-accent-yellow-500 text-accent-yellow-500",
      },
      {
        title: "Find with catlog",
        description: "Find Items on Twitter with the hashtag #findwithcatlog",
        icon: SearchIcon,
        path: "/findwithcatlog",
        color: "bg-accent-red-500 text-accent-red-500",
      },
      {
        title: "Deliveries",
        description: "Effortlessly get items delivered at discounted prices",
        icon: DeliveriesIcon,
        path: "/deliveries",
        // comingSoon: true,
        color: "bg-accent-orange-500",
      },
    ],
  },
  {
    title: "Pricing",
    icon: PricingIcon,
    path: "/pricing",
    color: "bg-accent-yellow-500",
  },
  {
    title: "Learn",
    icon: "",
    path: "",
    sub: [
      {
        title: "About Us",
        icon: AboutIcon,
        path: "/about",
        color: "bg-accent-red-500",
      },
      {
        title: "Blog",
        icon: BlogIcon,
        path: "/blog",
        color: "bg-accent-yellow-500",
      },
      {
        title: "Find Answers",
        icon: FaqsIcon,
        path: "/support",
        color: "bg-accent-green-500 text-accent-green-500",
      },
    ],
  },
];

export default navItems;
