import classNames from "classnames";
import Link from "next/link";
import React from "react";
import Badge from "../../ui/badge";

export interface NavItemType {
  title: string;
  icon: string;
  path: string;
  color?: string;
  sub?: {
    title: string;
    icon: React.FunctionComponent;
    path: string;
    color: string;
    description?: string;
    comingSoon?: boolean;
  }[];
}

const NavDropdown: React.FC<{ navItem: NavItemType }> = ({ navItem }) => {
  const isLarge = Boolean(navItem.sub[0]?.description);

  return (
    <div
      className={`nav-dropdown bg-white absolute top-full left-1/2 transform -translate-x-1/2 w-full max-w-5xl rounded-15 border border-grey-border border-opacity-30 z-[9999] ${
        isLarge ? "min-w-[660px] p-7.5" : "min-w-[210px] p-6.25"
      }`}
    >
      {/* prettier-ignore */}
      <svg width="32" viewBox="0 0 36 17" fill="none" className={`absolute top-0 transform -translate-y-full ${isLarge ? 'left-[53%]' : 'left-[53.5%]'}`}>
        <path d="M14.8493 1.82843C16.4114 0.26633 18.9441 0.26633 20.5062 1.82843L35.3554 16.6777H6.51479e-05L14.8493 1.82843Z" fill="white"/>
      </svg>

      <ul
        className={classNames({
          "gap-7.5 grid grid-cols-2": isLarge,
          "space-y-3.75": !isLarge,
        })}
      >
        {navItem.sub.map((item, index) => (
          <li key={index}>
            <Link href={item?.path}>
              <a
                className={classNames("flex dropdown-links", {
                  "items-center": !isLarge,
                  "items-start max-w-[275px]": isLarge,
                })}
              >
                <figure
                  className={`rounded-full bg-opacity-10 flex items-center justify-center flex-shrink-0 ${item.color} ${
                    isLarge ? "h-12.5 w-12.5" : "h-10 w-10"
                  }`}
                >
                  <div className={`h-[60%] w-[60%]`}>
                    <item.icon />
                  </div>
                </figure>
                <div
                  className={classNames({
                    "ml-3.75": !isLarge,
                    "ml-5": isLarge,
                  })}
                >
                  <div className="flex items-center">
                    <h6 className="text-base font-display font-bold text-black">{item.title}</h6>
                    {item?.comingSoon && (
                      <Badge className="ml-1.5" text="Coming soon" color="orange" greyBg={false} size="sm" />
                    )}
                  </div>
                  {item.description && (
                    <span className="inline-block mt-0.5 text-grey-subtext text-1xs max-w-[210px]">
                      {item.description}
                    </span>
                  )}
                </div>
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavDropdown;
