import React from "react";
import { getStyles } from "../../../assets/js/utils/functions";
import { getSizedImage } from "../../../assets/js/utils/utils";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import LazyImage from "../../lazy-image";
import { AppBtn } from "../../ui/buttons";
import AnimatedHeaderText from "./animated-header-text";

interface IProps {
  features: { description: string; icon: React.FunctionComponent }[];
  title?: React.ReactElement;
  cta?: {
    title: React.ReactElement;
    text: string;
  };
  image?: {
    src: string;
    alt: string;
  };
  headerTextSeries: string[][];
  reverse?: boolean;
  isWhiteBg?: boolean;
}

const FrontPagesFeatures: React.FC<IProps> = ({
  features,
  title,
  cta,
  image,
  headerTextSeries,
  reverse = false,
  isWhiteBg = false,
}) => {
  const { screen } = useScreenSize();
  return (
    <section className={`body-padding body-y-padding ${isWhiteBg ? "" : "bg-primary-pastel"}`}>
      <div className="container">
        <AnimatedHeaderText className="mb-10 sm:mb-12.5 lg:mb-15" series={headerTextSeries} />
        <div
          className={`w-full flex flex-col md:grid ${
            !reverse
              ? "md:grid-cols-[55%,45%] lg:grid-cols-[62%,38%]"
              : "md:grid-cols-[45%,55%] lg:grid-cols-[38%,62%] justify-items-end"
          }`}
        >
          <div
            className={`${
              reverse ? "md:pl-8" : "md:pr-8"
            } md:max-w-[680px] flex flex-col h-full w-full hide-for-observer`}
          >
            <div className="mb-5 sm:mb-7.5 rounded-15 md:flex-1 sm:rounded-3xl lg:rounded-30 overflow-hidden h-[85vw] sm:h-[75vw] md:h-[unset] relative safari-overflow-fix">
              <LazyImage
                src={
                  getSizedImage(image.src, { [BreakPoint.xs]: "w_650", default: "w_900" }, screen) ??
                  "/images/stock-photos/woman-with-headphones-large.png"
                }
                alt={image.alt}
                className="h-full w-full absolute top-0 left-0 object-cover image-appear"
              />
            </div>
            {cta && (
              <div
                className="bg-page-pastel rounded-15 sm:rounded-20 lg:rounded-3xl py-6.25 px-8 lg:py-7.5 lg:px-10 anim-word-up"
                style={getStyles({ "--delay": `.3s`, "--from": "15%", "--duration": "1s" })}
              >
                <h3 className="heading-five font-bold text-black !leading-[1.12]">{cta.title}</h3>
                <AppBtn size="lg" className="mt-6 md:mt-7.5 lg:mt-9">
                  {cta.text}
                  {/* prettier-ignore */}
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" className="ml-2.5 transform transition-all ease-out duration-100 group-hover:translate-x-0.5">
                  <path d="M6 0L4.9425 1.0575L9.1275 5.25H0V6.75H9.1275L4.9425 10.9425L6 12L12 6L6 0Z" fill="currentColor" />
                </svg>
                </AppBtn>
              </div>
            )}
          </div>
          <div
            className={`w-full flex flex-col space-y-5 sm:space-y-6.25 mb-7.5 sm:mb-10 md:mb-0 hide-for-observer -order-1 ${
              reverse ? "" : "md:order-1"
            }`}
          >
            {features.map((feature, index) => (
              <article
                className={`py-5 px-5.5 sm:py-6 sm:px-6.25 xl:py-6.25 xl:px-7.5 rounded-15 sm:rounded-20 lg:rounded-3xl border border-grey-fields-100 anim-word-up ${
                  isWhiteBg ? "bg-grey-fields-100" : "bg-white"
                }`}
                key={index}
                style={getStyles({ "--delay": `${index * 0.25}s`, "--from": "15%", "--duration": "1s" })}
              >
                <figure
                  className={`h-12.5 w-12.5 sm:h-15 sm:w-15 lg:h-16 lg:w-16 xl:h-17.5 xl:w-17.5 ${
                    isWhiteBg ? "bg-white" : "bg-grey-fields-200"
                  } rounded-full flex items-center justify-center`}
                >
                  <div className="w-1/2 text-page">
                    <feature.icon />
                  </div>
                </figure>
                <p className="mt-3.75 sm:mt-5 xl:mt-6 text-black font-medium sub-text lg:max-w-[380px]">
                  {feature.description}
                </p>
              </article>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrontPagesFeatures;
