import dynamic from "next/dynamic";
import React from "react";

const ContentScroller = dynamic(() => import("../commons/customer-scroller"), { ssr: true });

const ScrollerMemo = React.memo(
  () => {
    return <ContentScroller list={customers} />;
  },
  // NEVER UPDATE
  () => true
);

ScrollerMemo.displayName = "ScrollerMemo";

const FrontPagesCustomers = () => {
  return (
    <div className="flex flex-col mt-7.5 sm:mt-9 md:mt-11.25 lg:mt-12.5 items-center">
      <b className="font-semibold text-1sm sm:text-base md:text-lg lg:text-2xl text-black mb-2.5 sm:mb-4 md:mb-5 font-display">
        Trusted by 20k+ Businesses
      </b>

      <ScrollerMemo />
    </div>
  );
};

const customers = [
  {
    name: "Bernadines Bistro",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305498/website-store-logos/bernadines.webp",
    storeLink: "https://catlog.shop/pfbjxwquk5xsde82",
  },
  {
    name: "QueenChic",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/queen-chic.webp",
    storeLink: "https://catlog.shop/queenchicfancy",
  },
  {
    name: "Foodie24",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305498/website-store-logos/foodie24.webp",
    storeLink: "https://catlog.shop/foodie24",
  },
  {
    name: "Body Bang",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/body-bang",
    storeLink: "https://www.catlog.shop/bodybang",
  },
  {
    name: "All the Eves",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/all-theeves",
    storeLink: "https://www.catlog.shop/alltheeves",
  },
  {
    name: "Gray Hub",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/gray-hub",
    storeLink: "https://www.catlog.shop/j17izwrm",
  },
  {
    name: "Killz Bites",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/killz-bites",
    storeLink: "https://www.catlog.shop/v9xzi3q7",
  },
  {
    name: "Steady Gadgets",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/steady-gadgets",
    storeLink: "https://www.catlog.shop/steady-gadgets",
  },
  {
    name: "Feli’s bulk food services",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/felis-bulk-food",
    storeLink: "https://www.catlog.shop/etzpb8qs",
  },
  {
    name: "Shop Dichie",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305498/website-store-logos/shop-dichie.webp",
    storeLink: "https://catlog.shop/shopdichewear",
  },
  {
    name: "Delta Food",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/delta-relish.webp",
    storeLink: "https://catlog.shop/deltafoodng",
  },
  {
    name: "SV Lunchbox and Cocktails",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/sv-lunch-box.webp",
    storeLink: "https://catlog.shop/svlunchbox",
  },
  {
    name: "Zani's Cafe",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/zanis-cafe.webp",
    storeLink: "https://www.catlog.shop/zanis_cafe_productslist",
  },
  {
    name: "Debe Empire",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/debe-empire",
    storeLink: "https://catlog.shop/debeempire",
  },
  {
    name: "Bikegearng",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/bike-gear-ng",
    storeLink: "https://catlog.shop/bikegearng",
  },
  {
    name: "Grooveoutlets",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/groove-outlets",
    storeLink: "https://catlog.shop/grooveoutlets",
  },
  {
    name: "Benguy Fashion",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/benguy-fashion",
    storeLink: "https://www.catlog.shop/benguyfashion",
  },
  {
    name: "Flirt Fits",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/flirt-fits",
    storeLink: "https://catlog.shop/flirtfits",
  },
  {
    name: "To’phyn naturals",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/tophyn-naturals",
    storeLink: "https://www.catlog.shop/tophynnaturals1",
  },
  {
    name: "Resolute",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/resolute",
    storeLink: "https://catlog.shop/g3h3gka7",
  },
  {
    name: "Kelly's Empire",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/kellys-empire",
    storeLink: "https://www.catlog.shop/ajmjf58j",
  },
  {
    name: "Shopvams",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305498/website-store-logos/shop-vams.webp",
    storeLink: "https://catlog.shop/shopvams",
  },
  {
    name: "ShopTrav",
    logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305498/website-store-logos/shoptrav.webp",
    storeLink: "https://catlog.shop/695u66wtlj6be4f2",
  },
  // {
  //   name: "Jinja",
  //   logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/jinja.webp",
  //   storeLink: "https://catlog.shop/jinja",
  // },
  // {
  //   name: "Pasfruital",
  //   logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/pasfruital.webp",
  //   storeLink: "https://catlog.shop/wkk9w85dmbq1qh",
  // },
  // {
  //   name: "ShopwNyenye",
  //   logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/shop-w-nyenye.webp",
  //   storeLink: "https://catlog.shop/z35g62tq53wuie6",
  // },

  // {
  //   name: "Solemates NG",
  //   logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305498/website-store-logos/solemates.webp",
  //   storeLink: "https://catlog.shop/we28if5p84dtw39",
  // },
  // {
  //   name: "Javanne Signature",
  //   logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/javanne.webp",
  //   storeLink: "https://catlog.shop/javannnesignature",
  // },
  // {
  //   name: "Pancake Boss",
  //   logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305498/website-store-logos/pancake-boss.webp",
  //   storeLink: "https://catlog.shop/pancake-boss",
  // },
  // {
  //   name: "Home Effects Plus",
  //   logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/home-effects.webp",
  //   storeLink: "https://catlog.shop/homeffectsplus",
  // },
  // {
  //   name: "Mazzini",
  //   logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/mazzini.webp",
  //   storeLink: "https://catlog.shop/17r6gkutrmtbpi7w",
  // },
  // {
  //   name: "JIS STORE NG",
  //   logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/jis.webp",
  //   storeLink: "https://catlog.shop/jisstoreng",
  // },
  // {
  //   name: "4am Drip",
  //   logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/4am-drip.webp",
  //   storeLink: "https://catlog.shop/jtthh8spflztbm8",
  // },

  // {
  //   name: "XG Sport wears",
  //   logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305498/website-store-logos/xg-sport-wears.webp",
  //   storeLink: "https://catlog.shop/d13f6p1xlrpi9f6z",
  // },
  // {
  //   name: "The Happy Box",
  //   logo: "https://res.cloudinary.com/catlog/image/upload/c_scale,w_100/v1670305497/website-store-logos/the-happy-box.webp",
  //   storeLink: "https://catlog.shop/hty7bksrgj89ih7p",
  // },
];

export default FrontPagesCustomers;
