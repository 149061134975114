import classNames from "classnames";

export type BadgeColor = "green" | "yellow" | "red" | "orange" | "primary" | "dark";
interface Props {
  text: string;
  color?: BadgeColor;
  className?: string;
  size?: "sm" | "md" | "lg";
  greyBg?: boolean;
  uppercase?: boolean;
}

const Badge: React.FC<Props> = ({ text, color = "dark", className, size = "sm", greyBg = true, uppercase = true }) => {
  const badgeClasses = classNames(
    `font-semibold rounded-15 leading-none inline-flex`,
    {
      "text-accent-green-500": color === "green",
      "text-accent-yellow-500": color === "yellow",
      "text-accent-red-500": color === "red",
      "text-accent-orange-500": color === "orange",
      "text-primary-500": color === "primary",
      "text-black-placeholder": color === "dark",
      "py-1.5 px-2 text-xxs": size === "sm",
      "py-1.75 px-2.5 text-xs": size === "md",
      "py-2.5 px-3.75 text-1xs": size === "lg",
      "bg-grey-fields-100": greyBg,
      "bg-white": !greyBg,
      uppercase: uppercase,
      capitalize: !uppercase,
    },
    className
  );

  return <span className={badgeClasses}>{text}</span>;
};

export default Badge;
