import classNames from "classnames";
import Link from "next/link";
import React, { useState } from "react";
import { toAppUrl } from "../../../assets/js/utils/functions";
import Badge from "../../ui/badge";
import { AppBtn } from "../../ui/buttons";
import NavDropdown, { NavItemType } from "./nav-dropdown";
import navItems from "./nav-items";
const FrontPagesNavigation = () => {
  const [navIsOpen, setNavIsOpen] = useState(false);

  return (
    <nav className="body-padding h-18 sm:h-[90px] md:h-25 flex items-center relative !z-[99]">
      <div className="container flex items-center justify-between">
        <div className="flex items-center">
          <Link href="/">
            <a className="inline-block z-[999]">
              <img src="/images/logos/primary.svg" alt="Catlog Logo" className="h-6 sm:h-6.25 lg:h-7" />
            </a>
          </Link>
          <ul className="hidden items-center md:ml-4 lg:ml-11.5 md:space-x-3 lg:space-x-8 md:flex">
            {navItems.map((item, index) => (
              <li className="relative py-3.5 px-2 block cursor-pointer nav-item" key={index}>
                <Link href={item?.path}>
                  <a
                    href="#"
                    className="text-sm lg:text-1sm text-dark font-medium hover:text-black transition-all ease-out flex items-center"
                  >
                    {item.title}
                    {item.sub && (
                      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" className="ml-1.25 mt-px">
                        <path
                          d="M11.2 5.11249H7.30621H3.79996C3.19996 5.11249 2.89996 5.83749 3.32496 6.26249L6.56247 9.49999C7.08122 10.0187 7.92497 10.0187 8.44372 9.49999L9.67497 8.26874L11.6812 6.26249C12.1 5.83749 11.8 5.11249 11.2 5.11249Z"
                          fill="currentColor"
                        />
                      </svg>
                    )}
                  </a>
                </Link>
                {item?.sub && <NavDropdown navItem={item} />}
              </li>
            ))}
          </ul>
        </div>

        <div className="flex md:hidden">
          <input
            type="checkbox"
            id="front-nav-toggle-checkbox"
            onChange={(e) => setNavIsOpen(e.target.checked)}
            checked={navIsOpen}
            className="hidden"
          />
          <label className="front-nav-toggle cursor-pointer z-[999]" htmlFor="front-nav-toggle-checkbox">
            <span className="front-nav-toggle-inner"></span>
          </label>
          <div className="fixed inset-0 bg-white z-[990] mobile-nav flex flex-col pt-18 sm:pt-[90px]">
            <div className="flex-1 overflow-y-auto border-t border-grey-border border-opacity-50 pt-5 px-5">
              <div className="h-full max-w-lg mx-auto">
                {navItems.map((item, index) => (
                  <div
                    className={classNames("first:-mb-5", {
                      "border-b border-grey-border border-opacity-50 last:border-t last:border-b-0 pb-5 pt-5 mb-5 mt-5 first:pt-0 first:mt-0 last:mb-0":
                        item?.sub,
                    })}
                    key={index}
                  >
                    {item?.sub && (
                      <span className="text-1xs text-black-placeholder font-display font-semibold inline-block uppercase mobile-nav-item">
                        {item.title}
                      </span>
                    )}
                    {item?.sub && (
                      <ul className="flex flex-col space-y-4.5 mt-3.75 w-full">
                        {item.sub.map((sub, subIndex) => (
                          <li className="w-full mobile-nav-item" key={subIndex}>
                            <Link href={sub.path}>
                              <a className="flex items-center w-full justify-between group" href="#">
                                <div className="flex items-center">
                                  <figure
                                    className={`h-7.5 w-7.5 rounded-full bg-opacity-10 flex items-center justify-center ${sub.color}`}
                                  >
                                    <div className={`h-[60%] w-[60%]`}>
                                      <sub.icon />
                                    </div>
                                  </figure>
                                  <span className="ml-2.5 inline-block font-medium text-sm text-black-secondary">
                                    {sub.title}
                                  </span>
                                </div>
                                {sub?.comingSoon && <Badge text="Coming soon" />}
                                {!sub.comingSoon && <Caret />}
                              </a>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}

                    {!item.sub && (
                      <Link href={item.path}>
                        <a
                          className="flex items-center mb-2.5 w-full justify-between group mobile-nav-item-three"
                          href="#"
                        >
                          <div className="flex items-center">
                            <figure
                              className={`h-7.5 w-7.5 rounded-full bg-opacity-10 flex items-center justify-center ${item.color}`}
                            >
                              <div className={`h-[60%] w-[60%]`}>
                                <item.icon />
                              </div>
                            </figure>
                            <span className="ml-2.5 inline-block font-medium text-sm text-black-secondary">
                              {item.title}
                            </span>
                          </div>
                          {/* prettier-ignore */}
                          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" className="transform transition-all ease-out duration-100 group-hover:translate-x-0.5">
                          <path d="M4.98104 12.2065C4.81463 12.04 4.7995 11.7796 4.93566 11.5961L4.98104 11.5435L9.02438 7.5L4.98104 3.45646C4.81463 3.29004 4.7995 3.02962 4.93566 2.84612L4.98104 2.79354C5.14746 2.62713 5.40788 2.612 5.59138 2.74816L5.64396 2.79354L10.019 7.16854C10.1854 7.33496 10.2005 7.59537 10.0643 7.77888L10.019 7.83146L5.64396 12.2065C5.4609 12.3895 5.1641 12.3895 4.98104 12.2065Z" fill="#656565"/>
                        </svg>
                        </a>
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full mx-auto px-5 py-3 flex items-center flex-col mobile-nav-item-four border-t border-grey-border border-opacity-50">
              <AppBtn size="lg" isBlock className="max-w-lg" href={toAppUrl("app/sign-up")}>
                Start for free
                {/* prettier-ignore */}
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" className="ml-2.5 transform transition-all ease-out duration-100 group-hover:translate-x-0.5">
                  <path d="M6 0L4.9425 1.0575L9.1275 5.25H0V6.75H9.1275L4.9425 10.9425L6 12L12 6L6 0Z" fill="white" />
                </svg>
              </AppBtn>
              <a
                href={toAppUrl("app/login")}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block font-semibold text-1sm text-primary-500 mt-2 py-2.5 px-3 -mb-1.5"
              >
                Login
              </a>
            </div>
          </div>
        </div>
        <div className="items-center hidden md:flex">
          <a
            href={toAppUrl("app/login")}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm lg:text-1sm text-dark font-medium md:inline-block md:mr-4 lg:mr-8 hover:text-black transition-all ease-out hidden"
          >
            Log In
          </a>
          <AppBtn size="md" href={toAppUrl("app/sign-up")}>
            Start for free
            {/* prettier-ignore */}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" className="ml-2.5 transform transition-all ease-out duration-100 group-hover:translate-x-0.5">
              <path d="M6 0L4.9425 1.0575L9.1275 5.25H0V6.75H9.1275L4.9425 10.9425L6 12L12 6L6 0Z" fill="white" />
            </svg>
          </AppBtn>
        </div>
      </div>
    </nav>
  );
};

const Caret = () => {
  return (
    // prettier-ignore
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" className="transform transition-all ease-out duration-100 group-hover:translate-x-0.5">
      <path d="M4.98104 12.2065C4.81463 12.04 4.7995 11.7796 4.93566 11.5961L4.98104 11.5435L9.02438 7.5L4.98104 3.45646C4.81463 3.29004 4.7995 3.02962 4.93566 2.84612L4.98104 2.79354C5.14746 2.62713 5.40788 2.612 5.59138 2.74816L5.64396 2.79354L10.019 7.16854C10.1854 7.33496 10.2005 7.59537 10.0643 7.77888L10.019 7.83146L5.64396 12.2065C5.4609 12.3895 5.1641 12.3895 4.98104 12.2065Z" fill="#656565"/>
    </svg>
  );
};

export default FrontPagesNavigation;
