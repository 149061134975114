import classNames from "classnames";
import Head from "next/head";
import React, { useEffect, useState } from "react";
import YouTube, { YouTubePlayer } from "react-youtube";
import Portal from "../../portal";

export interface ModalProps {
  show: boolean;
  toggle: () => void;
  title?: string;
  ytVideoId: string;
}

const FrontPagesVideoPlayer: React.FC<ModalProps> = ({ show, toggle, ytVideoId }) => {
  const [showWidget, setShowWidget] = useState(show);
  const [isClosing, setIsClosing] = useState(false);
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    if (!show) {
      setIsClosing(true);

      setTimeout(() => {
        setShowWidget(show);
        setIsClosing(false);
      }, 200);
    } else {
      setShowWidget(show);
    }
  }, [show]);

  function _onReady(event) {
    // access to player in all event handlers via event.target
    // event.target.pauseVideo();
    setPlayer(event.target);
  }

  const wrapperClasses = classNames(
    "top-0 left-0 right-0 bottom-0 fixed z-[10000] bg-primary-900 bg-opacity-80 flex items-center justify-center px-5 py-15 blur-10",
    {
      "hidden pointer-events-none": !showWidget,
      block: showWidget,
      "modal-full-screen-anim-rev": isClosing,
    }
  );

  return (
    <Portal>
      <div className={wrapperClasses}>
        <button
          className="w-8 h-8 sm:h-10 sm:w-10 flex items-center bg-white justify-center absolute top-5 right-5 sm:top-8 sm:right-8 rounded-10"
          onClick={() => {
            player?.stopVideo();
            toggle();
          }}
        >
          {/* prettier-ignore */}
          <svg width="60%" viewBox="0 0 25 25" fill="none" className="text-primary-900">
            <path d="M18.2986 6.18994L6.29855 18.1899" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.29855 6.18994L18.2986 18.1899" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
        <div className="w-full max-w-[1000px]">
          <div className="w-full pt-[56.25%] relative bg-grey-fields-100 flex items-center justify-center">
            <div className="h-full w-full absolute top-0 left-0 z-50" id="clg-player">
              {(show || player) && (
                <YouTube
                  videoId={ytVideoId}
                  iframeClassName="h-full w-full absolute top-0 left-0"
                  opts={{
                    height: "100%",
                    width: "100%",
                    playerVars: {
                      autoplay: 0,
                    },
                  }}
                  onReady={_onReady}
                />
              )}
            </div>
            <div className="flex items-center justify-center h-full w-full absolute top-0 left-0">
              <div className="spinner spinner--md text-primary-500 absolute "></div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default FrontPagesVideoPlayer;
