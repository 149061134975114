import classNames from "classnames";
import React from "react";
import { getStyles, toAppUrl } from "../../../assets/js/utils/functions";
import { getSizedImage } from "../../../assets/js/utils/utils";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";
import LazyImage from "../../lazy-image";
import { AppBtn } from "../../ui/buttons";

interface CTAProps {
  className: string;
  subtext?: React.ReactElement;
}

const FrontPagesCTA: React.FC<CTAProps> = ({ className, children, subtext }) => {
  const { screen } = useScreenSize();
  return (
    <section
      className={classNames(
        "body-padding body-y-padding pb-[65vw] sm:!pb-80 md:!py-28 lg:!py-32 xl:!py-36 cta overflow-hidden relative",
        className
      )}
    >
      <div className="container">
        {/* <h3 className="heading-three text-black font-bold">{children}</h3> */}
        {children}

        <div className="hide-for-observer">
          <p
            className="mt-4 sm:mt-5 sub-text text-dark-secondary sm:max-w-md md:max-w-none slide-text-in"
            style={getStyles({ "--delay": "1.5s", "--from": "35%", "--duration": "1s" })}
          >
            {/* Join thousands of merchants using catlog to <br className="sm:hidden md:block" /> simplify the way they sell
            on social media */}

            {subtext ? (
              subtext
            ) : (
              <>
                Your customers buy from you on Whatsapp, <br className="sm:hidden md:block" /> don’t take them away,
                make it easier with Catlog.
              </>
            )}
          </p>
          <div
            className="flex items-center space-x-5 mt-8 sm:mt-10 lg:mt-12.5 slide-text-in"
            style={getStyles({ "--delay": "1.8s", "--from": "35%", "--duration": "1s" })}
          >
            <AppBtn size="lg" href={toAppUrl("app/sign-up")} className="inline-flex">
              Get started for free
              {/* prettier-ignore */}
              <svg viewBox="0 0 12 12" fill="none" className="ml-1.5 w-3 transform transition-all ease-out duration-100 group-hover:translate-x-0.5">
                <path d="M6 0L4.9425 1.0575L9.1275 5.25H0V6.75H9.1275L4.9425 10.9425L6 12L12 6L6 0Z" fill="currentColor" strokeWidth={1.5} />
              </svg>
            </AppBtn>
          </div>
        </div>
      </div>
      <LazyImage
        showLoader={false}
        src={getSizedImage(
          "https://res.cloudinary.com/catlog/image/upload/v1670357114/illustrations/storefront.webp",
          { [BreakPoint.xs]: "w_700", default: "w_1000" },
          screen
        )}
        alt="Store Illustration"
        classesBeforeLoad="block w-[120%] sm:mx-0 sm:w-[82%] md:w-[72%] lg:w-[60%] -bottom-[8%] absolute -right-[10%] sm:-right-[10%] lg:-right-[12%] sm:-bottom-[6%]  md:-bottom-[10%] sm:left-[unset] max-w-[850px] min-h-[800px]"
        className="block w-[120%] sm:mx-0 sm:w-[82%] md:w-[72%] lg:w-[60%] -bottom-[8%] absolute -right-[10%] sm:-right-[10%] lg:-right-[3%] sm:-bottom-[6%]  md:-bottom-[10%] sm:left-[unset] max-w-[850px]"
      />
    </section>
  );
};

export default FrontPagesCTA;
