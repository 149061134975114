import React from "react";
import { AppBtn } from "../../ui/buttons";
import BlogIcon from "../../../assets/icons/front-pages/blog.svg";
import ChatIcon from "../../../assets/icons/front-pages/chat.svg";
import FaqsIcon from "../../../assets/icons/front-pages/faqs.svg";
import { HELP_DESK_LINK, WHATSAPP_LINK } from "../../../assets/js/utils/constants";

const FrontPagesLearnMore = () => {
  return (
    <section className="body-padding body-y-padding">
      <div className="container max-w-[1000px]">
        <div className="w-full grid grid-cols-1 md:grid-cols-[1fr,45%] gap-5 lg:gap-7.5 items-stretch min-w-0">
          <article className="bg-accent-yellow-pastel rounded-15 px-8 py-11.25 sm:px-11.25 sm:py-15 lg:px-12.5 lg:py-18 xl:py-20 xl:px-15 w-full min-w-0 overflow-hidden">
            <figure className="h-20 w-20 sm:h-22.5 sm:w-22.5 lg:h-25 lg:w-25 rounded-full bg-white flex items-center justify-center">
              <div className="w-1/2">
                <BlogIcon />
              </div>
            </figure>
            <div className="mt-5 sm:mt-8 mb-8 sm:mb-12.5">
              <b className="heading-five text-black font-bold font-display">
                Stay updated <br className="hidden md:block" /> with Catlog
              </b>
              <p className="text-black-secondary sub-text max-w-[295px] sm:max-w-[unset] lg:mr-8 xl:mr-12 mt-2.5 sm:mt-3.75">
                Read articles on business tips & updates to the catlog platform
              </p>
            </div>
            <AppBtn size="lg" href="/blog" className="inline-flex">
              Read Blog
              {/* prettier-ignore */}
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" className="ml-2.5 transform transition-all ease-out duration-100 group-hover:translate-x-0.5">
                <path d="M6 0L4.9425 1.0575L9.1275 5.25H0V6.75H9.1275L4.9425 10.9425L6 12L12 6L6 0Z" fill="white" />
              </svg>
            </AppBtn>
          </article>
          <div className="flex flex-col w-full h-full space-y-5 lg:space-y-7.5 min-w-0 overflow-hidden">
            <article className="bg-accent-red-pastel flex-1 w-full rounded-15 sm:rounded-20 lg:rounded-[25px] p-8 sm:p-11.25 lg:px-10 lg:py-8">
              <figure className="h-15 w-15 sm:h-17.5 sm:w-17.5 lg:h-20 lg:w-20 bg-white rounded-full flex items-center justify-center">
                <div className="w-1/2 text-accent-red-500">
                  <FaqsIcon />
                </div>
              </figure>
              <b className="text-black font-bold text-xl sm:text-2lg lg:text-[25px] !leading-tight mt-6.25 font-display">
                Got Questions? Find <br />
                answers fast!
              </b>

              <a
                href={HELP_DESK_LINK}
                target="_blank"
                rel="noreferrer"
                className="flex items-center text-sm md:text-1sm font-semibold text-primary-500 mt-8 group"
              >
                Find Answers
                {/* prettier-ignore */}
                <svg viewBox="0 0 12 12" fill="none" className="ml-1.25 w-3 transform transition-all ease-out duration-100 group-hover:translate-x-0.5">
                  <path d="M6 0L4.9425 1.0575L9.1275 5.25H0V6.75H9.1275L4.9425 10.9425L6 12L12 6L6 0Z" fill="currentColor" strokeWidth={1.5} />
                </svg>
              </a>
            </article>
            <article className="bg-accent-orange-pastel flex-1 w-full rounded-15 sm:rounded-20 lg:rounded-[25px] p-8 sm:p-11.25 lg:px-10 lg:py-8">
              <figure className="h-15 w-15 sm:h-17.5 sm:w-17.5 lg:h-20 lg:w-20 bg-white rounded-full flex items-center justify-center">
                <div className="w-1/2">
                  <ChatIcon />
                </div>
              </figure>
              <b className="text-black font-display font-bold text-xl sm:text-2lg lg:text-[25px] !leading-tight mt-6.25">
                Need help? We’re <br /> always here
              </b>

              <a
                href={WHATSAPP_LINK}
                className="flex items-center text-sm md:text-1sm font-semibold text-primary-500 mt-8 group"
                target="_blank"
                rel="noreferrer"
              >
                Contact support
                {/* prettier-ignore */}
                <svg viewBox="0 0 12 12" fill="none" className="ml-1.25 w-3 transform transition-all ease-out duration-100 group-hover:translate-x-0.5">
                  <path d="M6 0L4.9425 1.0575L9.1275 5.25H0V6.75H9.1275L4.9425 10.9425L6 12L12 6L6 0Z" fill="currentColor" strokeWidth={1.5} />
                </svg>
              </a>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrontPagesLearnMore;
